(function ($) {

/* trigger when page is ready */
$(document).ready(function (){


if($('.infiniteContainer').length){

	/////////////////////////////
	// STOCKER URL SITE
	var baseTemplate = $('#urlTheme').data('url');
	console.log(baseTemplate);

	/////////////////////////////
	// APPARITION FONDU ARTICLE
	var apparitionMiniature = function(){
			//Fade
			for (var i = 0, nb = $('.listProj-bloc').length; i<nb; i++) {
				$('.listProj-bloc:eq('+i+')').delay(50*i).fadeTo('slow', 1);		 		
			};
			console.log(nb);
	};

	apparitionMiniature();



	/////////////////////////////
	// INFINITE SCROLL
	// voir ajout dans le fichier jquery.infinitescroll.js
	$('.infiniteContainer').infinitescroll({
		debug           : false,
		loading: {
			finishedMsg: "",
			img: baseTemplate + "/_prod/img/blank.gif",
			// img: baseTemplate + "/_/img/ajax-loader.svg",
			msgText: ""
		  },
		nextSelector    : ".next-posts a",
		bufferPx: 250, //Départ chargement + élevé = + rapide
		navSelector     : "#page-nav",
		contentSelector : ".infiniteContainer",
		itemSelector    : ".infiniteContainer article",
		animate: false,
		},function(){ 
			apparitionMiniature();
			imgLiens();

		});

}


/////////////////////////////
// PAS DE SOULIGNEMENT SOUS LES IMAGES
// hack pour les anciens posts de vews (les nouveaux insert auront le style via un filtre PHP)
var imgLiens = function(){
	$('.content a > img').parent().addClass('no-effect');
};
imgLiens();

/////////////////////////////
// GESTION SAUT DE COLONNE
$('.deuxCols p > span.headline2').parent('p').each(function(){
    $(this).next('p').andSelf().wrapAll('<div class="lierColonne"/>');
});

/////////////////////////////
// SURVOL SUR MOBILE : projet et slide home
//http://www.hnldesign.nl/work/code/mouseover-hover-on-touch-devices-using-jquery/
if ($('.tablet, .mobile').length) {
	console.log('mode mobile/tablet');

	//Miniature projets
	$('.listProj-liste').on("click", '.listProj-bloc a',function (e) {
	    "use strict"; //satisfy the code inspectors
	    var link = $(this); //preselect the link
	    if (link.hasClass('over')) {
	        return true;
	        console.log('ouvrir le lien');
	    } else {
	        link.addClass("over");
	        $('.listProj-bloc a').not(this).removeClass("over");
	        e.preventDefault();
	        console.log('ajout over + bloc lien');
	        return false; //extra, and to make sure the function has consistent return points
	    }
	});

	//Slideshow accueil
	$('#featuredSlide').on("click", '.homeSlide-slide a',function (e) {
	    "use strict"; //satisfy the code inspectors
	    var link = $(this); //preselect the link
	    if (link.hasClass('over')) {
	        return true;
	        console.log('ouvrir le lien');
	    } else {
	        link.addClass("over");
	        $('.homeSlide-slide a').not(this).removeClass("over");
	        e.preventDefault();
	        console.log('ajout over + bloc lien');
	        return false; //extra, and to make sure the function has consistent return points
	    }
	});
};


/////////////////////////////
// VEWS LINK LIST
$('.btnList').click(function(){
	$(this).toggleClass('open');
	$('.linkList').toggleClass('open');
})


/////////////////////////////
// MENU MOBILE

//Menu principal
$('#btnMenuMobile').click(function(){
	console.log('test');
	$('#nav-toggle').toggleClass('active');
	$('#nav').toggleClass('open');
	$('body').toggleClass('noScroll');

	//Fermer le ss menu sur vews et projects si ouvert
	if($('#nav-cat.open').length){
		$('#nav-cat').removeClass('open');
	}

});

//Menu catégorie
$('#nav-cat-btn').click(function(){
	$('#nav-cat').toggleClass('open');
});
if($('.current-cat').length){
	var resultChoice = $('.current-cat a').text();
	$('#nav-cat-btn').text('category: '+resultChoice);
}

//Vews/projects : Marque le premier lien du sous-menu comme selectionné
if($('.post-type-archive-vews').length || $('.page-template-page-projects').length){
	$('.all-cat').addClass('current-cat');
}

//Vews/projects : Marque projects ou vews comme page parente
if($('.single-post').length || $('.category').length){
	$('.page-item-394').addClass('current_page_parent');
}
if($('.single-vews').length || $('.tax-categories').length || $('.post-type-archive-vews').length ){
	$('.page-item-396').addClass('current_page_parent');
}

//Ouverture des sous page au clic sur le parent
if($('#btnMenuMobile').is(':visible')){
	$('#nav .page_item_has_children > a').click(function(e){
		e.preventDefault();
		$(this).next().slideToggle();		
	});
};

///////////////////////////////////////////////////
// SCROLL = home + fiche projet
$('.boutonPrincipes a, #project_nav a').click( function() {
			//Page projet : marquer les sections active en noir
			if ($('#project_nav').length) {
				$('#project_nav a').removeClass('active');
				$(this).addClass('active');

			}; 

			var section = $(this).attr('href'); // Page cible
			var speed = 750; // Durée de l'animation (en ms)
			var headerHeight;
			if($('.barreBlanche').is(':visible')){
				headerHeight = $('.barreBlanche').outerHeight(); 
			}else{
				headerHeight =  $('#header').outerHeight();
			}
			var posTop;
			if($('.home').length){
				posTop = $(section).offset().top - headerHeight;				
			}else{
				posTop = $(section).offset().top - headerHeight - 20;				
			}
			console.log('headerHeight: ' + headerHeight + ' posSection: ' + $(section).offset().top + ' total: ' + posTop);
			$('html, body').animate( { scrollTop: posTop }, speed ); // Go
			//return false;
		});

///////////////////////////////////////////////////
// SHARE PROJET : apparait au scroll
if($('.single-post, .single-vews').length && !$('#btnMenuMobile').is(':visible')){
	
	$(document).scroll(function () {
		var y = $(this).scrollTop();
		if (y > 200) {
			$('.share-buttons').fadeIn();
		} else {
			$('.share-buttons').fadeOut();
		}
	});
}
	
///////////////////////////////////////////////////
// FLEXSLIDER
// http://www.woothemes.com/flexslider/

// Slideshow home
$('.homeSlide').flexslider({
	animation: "slide",              //String: Select your animation type, "fade" or "slide"
	animationLoop: true,             //Boolean: Should the animation loop? If false, directionNav will received "disable" classes at either end
	smoothHeight: false,            //{NEW} Boolean: Allow height of the slider to animate smoothly in horizontal mode  
	slideshow: true,                //Boolean: Animate slider automatically
	slideshowSpeed: 3000,           //Integer: Set the speed of the slideshow cycling, in milliseconds
	animationSpeed: 600,            //Integer: Set the speed of animations, in milliseconds
	pauseOnAction: true,            //Boolean: Pause the slideshow when interacting with control elements, highly recommended.
	pauseOnHover: true,            //Boolean: Pause the slideshow when hovering over slider, then resume when no longer hovering
	keyboard: false,                 //Boolean: Allow slider navigating via keyboard left/right keys
	controlNav: false,               //Boolean: Create navigation for paging control of each clide? Note: Leave true for manualControls usage
	touch: true,                    //{NEW} Boolean: Allow touch swipe navigation of the slider on touch-enabled devices
    customDirectionNav: $(".homeSlide-nav a"),
//    customDirectionNav: $(".homeSlide-nav"),
	//Afficher quand il est prêt
	start: function(){
		$('.homeSlide').fadeTo( "slow", 1 );
	},//Callback: function(slider) - Fires when the slider loads the first slide
	
	//EVITER LE FLOU : desactiver les transitions CSS (pb dû à transition3D)
	useCSS: false,                   //{NEW} Boolean: Slider will use CSS3 transitions if available
  });

//gestion hover
//$('.featuredSlide').mouseenter(function(){
if ($('.desktop').length) {
	$('.homeSlide').mouseenter(function(){
		$(this).addClass('over');
	}).mouseleave(function(){
		$(this).removeClass('over');
	});
};


// Carroussel Fiche projet
// http://flexslider.woothemes.com/dynamic-carousel-min-max.html
$('.related-flexslider').flexslider({
	animation: "slide",              //String: Select your animation type, "fade" or "slide"
	animationLoop: false,             //Boolean: Should the animation loop? If false, directionNav will received "disable" classes at either end
	smoothHeight: false,            //{NEW} Boolean: Allow height of the slider to animate smoothly in horizontal mode  
	slideshow: false,                //Boolean: Animate slider automatically
	slideshowSpeed: 3000,           //Integer: Set the speed of the slideshow cycling, in milliseconds
	animationSpeed: 600,            //Integer: Set the speed of animations, in milliseconds
	pauseOnAction: true,            //Boolean: Pause the slideshow when interacting with control elements, highly recommended.
	pauseOnHover: true,            //Boolean: Pause the slideshow when hovering over slider, then resume when no longer hovering
	keyboard: false,                 //Boolean: Allow slider navigating via keyboard left/right keys
	controlNav: false,               //Boolean: Create navigation for paging control of each clide? Note: Leave true for manualControls usage
    customDirectionNav: $(".related-nav"),
    itemWidth: 500,                   //{NEW} Integer: Box-model width of individual carousel items, including horizontal borders and padding.
	itemMargin: 0,                  //{NEW} Integer: Margin between carousel items.
	minItems: 1,                    //{NEW} Integer: Minimum number of carousel items that should be visible. Items will resize fluidly when below this.
	maxItems: 3,                    //{NEW} Integer: Maxmimum number of carousel items that should be visible. Items will resize fluidly when above this limit.
	move: 0,                        //{NEW} Integer: Number of carousel items that should move on animation. If 0, slider will move all visible items.
	//Afficher quand il est prêt
	start: function(){
		$('.related-flexslider').fadeTo( "slow", 1 );
	},            //Callback: function(slider) - Fires when the slider loads the first slide

	//EVITER LE FLOU : desactiver les transitions CSS (pb dû à transition3D)
	useCSS: false,                   //{NEW} Boolean: Slider will use CSS3 transitions if available                                
  });

//SLIDER VIDEO
$('.video-flexslider').flexslider({
	animation: "slide",              //String: Select your animation type, "fade" or "slide"
	animationLoop: true,             //Boolean: Should the animation loop? If false, directionNav will received "disable" classes at either end
	smoothHeight: false,            //{NEW} Boolean: Allow height of the slider to animate smoothly in horizontal mode  
	slideshow: false,                //Boolean: Animate slider automatically
	keyboard: false,                 //Boolean: Allow slider navigating via keyboard left/right keys
	directionNav: false,             //Boolean: Create navigation for previous/next navigation? (true/false)
	controlNav: true,               //Boolean: Create navigation for paging control of each clide? Note: Leave true for manualControls usage
	touch: false,                    //{NEW} Boolean: Allow touch swipe navigation of the slider on touch-enabled devices
	video: true,                   //{NEW} Boolean: If using video in the slider, will prevent CSS3 3D Transforms to avoid graphical glitches
	//Afficher quand il est prêt
	start: function(){
		//Callback: function(slider) - Fires when the slider loads the first slide
		$('.video-flexslider').fadeTo( "slow", 1 );
	},            
	before: function(){
		//Callback: function(slider) - Fires asynchronously with each slider animation
		console.log('clic video');
	},


	//EVITER LE FLOU : desactiver les transitions CSS (pb dû à transition3D)
	useCSS: false,                   //{NEW} Boolean: Slider will use CSS3 transitions if available

  });



///////////////////////////////////////////////////
// SUPPRIMER LE CLIC DROIT
$('img').bind('contextmenu', function(e) {
    return false;
}); 



});

}(window.jQuery || window.$));